import {backendApi} from "../backend-api-sr"
//import {backendApi} from "../../backend-api-sr"

export const filter = (() =>{
    const test = (() => {
        return ""
    })

    const fetchforecastfilter = ( async (subPath) => {
        var url = '/api/viva/forecast/getFilter'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchforecast = ( async (subPath) => {
        var url = '/api/viva/forecast'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchforecastbrand = ( async (subPath) => {
        var url = '/api/viva/forecast/sumbrand'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchbrand = ( async (subPath) => {
        var url = '/v2/master/kmb/barang_jadi/brand'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchchart = ( async (subPath) => {
        var url = '/api/viva/forecast/chartForecast'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchforecastdua = ( async (subPath) => {
        var url = '/api/viva/forecast/sume'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchsum = ( async (subPath) => {
        var url = '/api/viva/forecast/sum'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchEnvconf = ( async (subPath) => {
        var url = '/api/viva/forecast/envConfGroup'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchGroup = ( async (subPath) => {
        var url = 'api/viva/forecast/envConfGroup'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchEnvConfUser = ( async (reqBody) => {
        var url = '/api/viva/forecast/envConfUser'
        // if(subPath != null || subPath != ""){
        //     url = url + subPath;
        // }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const getSummaryRegionBrand = ( async (subPath) => {
        var url = '/api/viva/forecast/getSummaryRegionBrand'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const getSummaryRegionBrandOffice = ( async (subPath) => {
        var url = '/api/viva/forecast/getSummaryRegionBrandOffice'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })
   
    return {fetchforecastfilter, fetchforecast, fetchforecastdua, fetchforecastbrand, fetchEnvconf, fetchGroup, fetchEnvConfUser,fetchsum, fetchchart, fetchbrand, test, getSummaryRegionBrand, getSummaryRegionBrandOffice};

})()